<template>
    <div>
         <b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				<b-col sm="12" md="4">
					<label>Estado(s) de formulario</label>
					<v-select 
                        multiple
					    v-model="filterState"
					    :options="optionsStates" />
				</b-col>
                <b-col sm="12" md="4">
					<label>Rubro(s)</label>
					<v-select 
                        multiple
					    v-model="filterBusinessArea"
					    :options="optionsBusinessAreas" />
				</b-col>
                <b-col sm="12" md="4">
                    <label for="monthAndYear">Mes/Año de evaluación</label>
                    <b-input-group>
                        <flat-pickr
                            id="monthAndYear"
                            v-model="filterMonthAndYearEvaluation"
                            class="form-control"
                            :config="config"
                        />
                        <b-input-group-append>
                            <b-button
                                variant="primary"
                                @click="clearDate"
                            >
                                <feather-icon
                                    icon="XIcon"
                                    size="12"
                                />
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>

                </b-col>
            </b-row>
		</b-card>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>  

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block"
                                placeholder="Buscar ..."
                            />
                        </div>
                    </b-col>                  

                </b-row>

            </div>
            
            <b-table
                ref="refFormListTable"
                class="position-relative"
                :style="totalUsers < 3 && totalUsers > 0 ? 'height: 280px' : 'height: inherit'"
                :items="fetchForms"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(month_and_year)="data">
                    {{ getDateFormat(data.item.start_date, true) }}                
                </template>

                <template #cell(state)="data">           
                    <b-badge :variant="`light-${data.item.state.color}`">
                        {{data.item.state.name}}
                    </b-badge>                  
                </template>
                
                <template #cell(start_date)="data">
                    <b-col style="width: 200px">
                        {{ getDateFormat(data.item.start_date) }} 00:00:00 
                    </b-col>   
                </template>
                
                <template #cell(date_dispatch_review)="data">
                    <b-col style="width: 250px">
                        {{ getDateFormat(data.item.date_dispatch_review, false, true) }}
                     </b-col> 
                </template>

                <template #cell(options)="data">
                    <b-button
                        block
                        v-ripple.400="'rgba(255, 159, 67, 0.15)'"                                     
                        :to="{ name: 'medisalud-form-edit', params: { id: data.item.id }, query: { form: data.item}}"                               
                        :variant="`outline-${getButtonState(data.item.state.id).variant}`"   
                        :disabled="[States.EN_REVISION, States.COMPLETADO].includes(data.item.state.id) ? false : true"                                               
                    >
                        <feather-icon :icon="getButtonState(data.item.state.id).icon" />
                        {{ getButtonState(data.item.state.id).text }}
                    </b-button>
                </template>

            </b-table>
        
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>
             
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js';
    import { onUnmounted, reactive } from '@vue/composition-api';
    import 'flatpickr/dist/plugins/monthSelect/style.css';
    import { VBTooltip, VBModal } from 'bootstrap-vue';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import formStoreModule from '../formStoreModule';
    import flatPickr from 'vue-flatpickr-component';
    import Ripple from 'vue-ripple-directive';
    import useFormsList from './useFormsList';
    import { States } from '@core/utils/data';
    import vSelect from 'vue-select';
    import store from '@/store';
    import moment from 'moment';
    import axios from '@axios';

    export default {
        components: {
            flatPickr,       
            vSelect
        },
        directives: {
            'b-modal': VBModal,            
            'b-tooltip': VBTooltip,
            Ripple
        },
        data() {                              
            return {
                States,
                config: {
                    plugins: [
                        new monthSelectPlugin({
                            shorthand: true,
                            dateFormat: "m/Y"
                        })
                    ],
                    locale: Spanish
                }
            }
        },
        methods: {
            getButtonState (state_id) {
                let icon = 'ClockIcon', text = 'Revisión Pendiente', variant = 'dark'
                if (state_id === this.States.COMPLETADO) {
                    icon = 'CheckSquareIcon'
                    text = 'Revisión Terminada'
                    variant = 'success'
                }
                if (state_id === this.States.NO_REALIZADO) { 
                    icon = 'XCircleIcon'
                    text = 'Revisión Cancelada'
                }
                if (state_id === this.States.EN_REVISION) {
                    icon = 'EditIcon'
                    text = 'Revisar Formulario'
                    variant = 'info'
                }
                return { icon, text, variant }                
            },

            getDateFormat (date, subtract, dispatch) {
                if (!date) return ''

                let dateFormat = moment(date).format('DD-MM-YYYY')

                if (subtract) dateFormat = moment(date).subtract(1, 'months').format('MM/YYYY') 
                if (dispatch) dateFormat = moment(date).format('DD-MM-YYYY HH:mm A')
                
               return dateFormat
            }            
        },
        setup() {
            
            const USER_APP_STORE_MODULE_NAME = 'app-medisalud-form';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });

            const optionsBusinessAreas = reactive([]);
            axios.get('/business-area/select')
            .then(response => {
                response.data.map( item => {
					optionsBusinessAreas.push({
						label : item.name, 
						value : item.id
					});
				});
            });
               
            const optionsStates = reactive([]);            
            axios.get('/state/select')
            .then(response => {
                 response.data.map( item => {
					optionsStates.push({
						label : item.name, 
						value : item.id
					});
				});
            });

            const clearDate = () => {
                filterMonthAndYearEvaluation.value = null;
            };
            
            const {
                fetchForms,
                tableColumns,
                perPage,
                searchQuery,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,
                
                filterState,
                filterBusinessArea,
                filterMonthAndYearEvaluation
            } = useFormsList();

            return {
                searchQuery,
                fetchForms,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,       

                // Filter      
                optionsBusinessAreas,          
                optionsStates,
                filterState,
                filterBusinessArea,
                filterMonthAndYearEvaluation,

                // METHODS
                clearDate
            }
        }
    }

</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }    
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>